import React, {useState}  from 'react'
import { Row, Col,  Button, Modal, Radio} from "antd";
import { useNavigate, Link } from "react-router-dom";

function LibraryContentDetails() {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
  return (
    <>
    <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
        <div className='librarydetails'>
            <Row align="middle">
                <Col xs={24} md={24} lg={16} xl={19}><h4>The Works of the Temple Masonic</h4></Col>
                <Col xs={24} md={24} lg={6} xl={5}>
                    <Row justify="space-between">
                        <div className='purchasebtn mousepointer'  onClick={showModal} >Purchase this Book</div>
                        <div><span className='mousepointer'><u>1ARM-TTM</u></span></div>
                    </Row>
                </Col>
                <Col xs={24}>
                    <p>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. It Has Survived Not Only Five Centuries, But Also The Leap Into Electronic Typesetting, Remaining Essentially Unchanged.</p>

                    <p>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. It Has Survived Not Only Five Centuries, But Also The Leap Into Electronic Typesetting, Remaining Essentially Unchanged.</p>

                    <p>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. It Has Survived Not Only Five Centuries, But Also The Leap Into Electronic Typesetting, Remaining Essentially Unchanged.</p>

                    <p>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. It Has Survived Not Only Five Centuries, But Also The Leap Into Electronic Typesetting, Remaining Essentially Unchanged.</p>

                    <p>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. It Has Survived Not Only Five Centuries, But Also The Leap Into Electronic Typesetting, Remaining Essentially Unchanged.</p>

                    <p>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. It Has Survived Not Only Five Centuries, But Also The Leap Into Electronic Typesetting, Remaining Essentially Unchanged.</p>

                    <p>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book. It Has Survived Not Only Five Centuries, But Also The Leap Into Electronic Typesetting, Remaining Essentially Unchanged.</p>
                </Col>
            </Row>

        </div>
        </Col>
        </Row>
        <Modal  open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
      <Row justify={"center"}>
          <Col xs={22} md={22}>
            <h4>Congrats!</h4>
          </Col>

          <Col xs={22} md={22}>
            <div className="modalinner">
              <p>
              Please Choose Book Type Below:
              </p>
              <Radio.Group >
                <Radio value={1}>I'm good with E-book</Radio>
                <Radio value={2}>I want a hardcopy</Radio>
              </Radio.Group>
            </div>
          </Col>

          <Col xs={22} md={22} style={{ textAlign: "center" }}>
            <div className="modalinner">
              <Button className="mainbtn padding-x-40" onClick={() => navigate("/check-out")}>Checkout</Button>
              <Button className="mainbtn padding-x-50 margin-x-10"  onClick={handleCancel}>
                Later
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
    
  )
}

export default LibraryContentDetails