import React, {useState} from 'react'
import { Row, Col} from 'antd'
import StoreProduct from '../../components/storeproduct'

function SupplyStore() {
    const [StoreProductCards, setStoreProductrCards] = useState([
        {
          key: 1,
          image: "./images/product1.png",
          title: "Grand Lodge Past",
          ratingNumber:"10",
          price:"$180",
          rating:5
        },
        {
            key: 2,
            image: "./images/product2.png",
            title: "Grand Lodge Past",
            ratingNumber:"10",
            price:"$180",
            rating:4
          },
          {
            key: 3,
            image: "./images/product3.png",
            title: "Grand Lodge Past",
            ratingNumber:"10",
            price:"$180",
            rating:3
          },
          {
              key: 4,
              image: "./images/product4.png",
              title: "Grand Lodge Past",
              ratingNumber:"10",
              price:"$180",
              rating:3.2
            },
            {
                key: 5,
                image: "./images/product1.png",
                title: "Grand Lodge Past",
                ratingNumber:"10",
                price:"$180",
                rating:4.5
              },
              {
                  key: 6,
                  image: "./images/product2.png",
                  title: "Grand Lodge Past",
                  ratingNumber:"10",
                  price:"$180",
                  rating:5
                },
                {
                  key: 7,
                  image: "./images/product3.png",
                  title: "Grand Lodge Past",
                  ratingNumber:"10",
                  price:"$180",
                  rating:2.2
                },
                {
                    key: 8,
                    image: "./images/product4.png",
                    title: "Grand Lodge Past",
                    ratingNumber:"10",
                    price:"$180",
                    rating:5
                  },
                  {
                    key: 9,
                    image: "./images/product1.png",
                    title: "Grand Lodge Past",
                    ratingNumber:"10",
                    price:"$180",
                    rating:4.5
                  },
                  {
                      key: 10,
                      image: "./images/product2.png",
                      title: "Grand Lodge Past",
                      ratingNumber:"10",
                      price:"$180",
                      rating:3.5
                    },
                    {
                      key: 11,
                      image: "./images/product3.png",
                      title: "Grand Lodge Past",
                      ratingNumber:"10",
                      price:"$180",
                      rating:2.5
                    },
                    {
                        key: 12,
                        image: "./images/product4.png",
                        title: "Grand Lodge Past",
                        ratingNumber:"10",
                        price:"$180",
                        rating:2.5
                      },
      ]);
  return (
    <>
        <Row justify="center"  className='whitebg'>
        <Col  xs={22} md={20} xl={18}>
            <Row justify="center"> 
            <Col  xs={22} md={20} xl={14}>
                <h2>Supply Store</h2>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua</p>
            </Col>
            </Row>
            <Row justify="center" gutter={20}>
                <StoreProduct StoreProductCards={StoreProductCards}/>
                
            </Row>
        </Col>
        </Row>

        
    </>
  )
}

export default SupplyStore