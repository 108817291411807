import React from 'react'
import { Col, Row,} from "antd";
import LockedBook from '../../components/lockbook';
import {AiOutlineArrowLeft} from 'react-icons/ai';

function FeaturedbooksPage() {
  return (
    <>
  

    <div className='featuredarea'>
        <Row justify='center'>
            <Col xs={22} md={18} lg={18}>
                <Row  justify='center'>
                <Col xs={22} md={22} lg={22} className=''>
                    <h2><AiOutlineArrowLeft /> Featured Books</h2>
                     </Col>
                <Col xs={22} md={18} lg={14} className=''>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
                </Col>
                <Row>
                    <Col xs={24}>
                    <LockedBook/>

                    
                    </Col>

                    
                </Row>
                </Row>
            </Col>
        </Row>
    </div>
    </>
  )
}

export default FeaturedbooksPage