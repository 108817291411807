import React, { useState } from 'react';
import { Button, message, Steps, theme, Row, Col } from 'antd';
import OrderSummary from '../../components/orderSummary'
import { AiFillIdcard,AiFillCreditCard, AiFillCheckSquare } from 'react-icons/ai';
import PersonalInformation from './personalInformation';
import Payment from './payment';
import ConfirmOrderDetails from './confirmOrderDetails';

const steps = [
    {
      title: 'Address',
      content: 'First-content',
      icon: <AiFillIdcard/>,
      
    },
    {
      title: 'Payment',
      content: 'Second-content',
      icon: <AiFillCreditCard />,
    },
    {
      title: 'Confirm',
      content: 'Last-content',
      icon: <AiFillCheckSquare />,
    },
  ];
function CheckOut() {
    const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon:item.icon
  }));

  return (
    <>
    <Row justify="center"  className='whitebg steps'>
    <Col  xs={22} md={20} xl={18}>
        <Row justify="center"> 
        <Col  xs={24} md={20} xl={14}>
            <h2>Checkout</h2>
        </Col>
        </Row>
        <Row justify="center" gutter={50} className='padding-y-40 tnc'>
            
            <Col xs={24} md={22} lg={22}>
            <Row gutter={30}>
             
             <Col xs={24} md={15} lg={17}>
             <>
      <Steps current={current} items={items} labelPlacement='vertical' />
      {current == 0 && <PersonalInformation/>}
      {current == 1 && <Payment/>}
      {current == 2 && <ConfirmOrderDetails/>}
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()} className='mainbtn'>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}  className='mainbtn'>
            Place Order
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}  className='mainbtn'
          >
            Previous
          </Button>
        )}
      </div>
    </>
             </Col>
             <Col xs={24} md={9} lg={7}>
               <OrderSummary subTotal={100} tax={10} CheckOut={false} />
             </Col>
           </Row>
            </Col>
        </Row>
    </Col>
    </Row>

    
</>
  )
}

export default CheckOut