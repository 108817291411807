import React, { useRef } from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  Form,
  Layout,
  Input,
  Image,
  Button,
  Card,
} from "antd";
import { useNavigate, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import swal from "sweetalert";
import { Link } from "react-router-dom";

// import router from "next/router";

function ForgetPasswordCode() {
  const { state } = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let input_1 = useRef();

  let input_2 = useRef();

  let input_3 = useRef();

  let input_4 = useRef();

  const user = useSelector((state) => state.user.userData);

  const token = useSelector((state) => state.user.userToken);

  const [loading, setLoading] = React.useState(false);

  let [codeData, setCodeData] = React.useState({
    input1: "",

    input2: "",

    input3: "",

    input4: "",
  });

  console.log("state", state);

  // import router from "next/router";

  const onFinish = () => {
    let code =
      codeData.input1 + codeData.input2 + codeData.input3 + codeData.input4;

    if (
      codeData.input1 == "" ||
      codeData.input2 == "" ||
      codeData.input3 == "" ||
      codeData.input4 == ""
    ) {
      swal("Error", "Incomplete Code", "error");
    }

    Post(AUTH.verifyCode, { code, email: state.email })
      .then((response) => {
        setLoading(false);

        if (response?.data?.status) {
          swal("Success", response?.data?.message, "success");

          navigate("/forgot-password-3", {
            replace: true,
            state: { code, email: state.email },
          });
        } else {
          swal(
            "Oops!",
            response?.data?.message || response?.response?.data?.message,
            "error"
          );
        }
      })

      .catch((e) => {
        console.log(e, "ww");

        swal("Oops!", "internal server error", "error");

        setLoading(false);
      });
  };

  function handleInputChange(name, value) {
    setCodeData({ ...codeData, [name]: value });
  }

  return (
    <Layout className="banner">
      <Row className="flex" style={{ minHeight: "100vh" }}>
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col xs={24} md={10}>
            <Card>
              <div className="authFormBox">
                <Row style={{ width: "100%", justifyContent: "center" }}>
                  <Col xs={20} md={20} className="formWrap">
                    <Row style={{ width: "100%", textAlign: "center" }}>
                      <Col xs={24} md={0}>
                        <Image
                          src={"/images/logo.png"}
                          style={{ maxWidth: "200px" }}
                          alt=""
                          preview={false}
                        />
                      </Col>
                    </Row>
                    <Typography.Title
                      className="fontFamily1"
                      style={{ fontSize: "30px", color: "black", textTransform:"uppercase"  }}
                    >
                      Forgot Password
                    </Typography.Title>
                    <Typography.Text
                      className="fontFamily1"
                    >
                      <p style={{ fontSize: "12px", color: "black" }}>
                      An Email Has Been Sent To You With A Verification Code.
                    </p>
                    </Typography.Text>
                   
                    <div>
                      <Row gutter={15} style={{ width: "100%", justifyContent: "center" }}>
                        <Col>
                          <input
                            autoFocus
                            type="text"
                            pattern="\d*"
                            maxlength="1"
                            className="codeInput"
                            onChange={(text) => {
                              input_1.focus();
                              handleInputChange(
                                "input1",
                                text.target.value.toLowerCase()
                              );
                            }}
                          />
                        </Col>
                        <Col>
                          <input
                            type="text"
                            maxlength="1"
                            className="codeInput"
                            ref={(val) => (input_1 = val)}
                            onChange={(text) => {
                              input_2.focus();
                              handleInputChange(
                                "input2",
                                text.target.value.toLowerCase()
                              );
                            }}
                          />
                        </Col>
                        <Col>
                          <input
                            type="text"
                            maxlength="1"
                            className="codeInput"
                            ref={(val) => (input_2 = val)}
                            onChange={(text) => {
                              input_3.focus();
                              handleInputChange(
                                "input3",
                                text.target.value.toLowerCase()
                              );
                            }}
                          />
                        </Col>
                        <Col>
                          <input
                            type="text"
                            maxlength="1"
                            className="codeInput"
                            ref={(val) => (input_3 = val)}
                            onChange={(text) => {
                              handleInputChange(
                                "input4",
                                text.target.value.toLowerCase()
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                    <br />
                    <Row  style={{ justifyContent: "end" }}>
                        <Col>
                        <Link 
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "#AE802C",
                        }}>Resend Code</Link>
                        </Col>
                    </Row>
                    <br />
                    <Button onClick={() => navigate("/passwordrecovery")}
                      type="primary"
                      htmlType="submit"
                      className="loginButton mainbtn"
                    //   onClick={() => onFinish()}
                    >
                      {loading ? "Loading..." : "Continue"}
                    </Button>
                
                    <Row style={{ width: "100%", justifyContent: "center" }}>
                      <Col xs={24} md={12}>
                       

                        <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "14px",
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 30,
                    }}
                  >
                    <br />
                    <>
                    Back To  
                      <span
                        onClick={() => navigate("/login")}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "#AE802C",
                        }}
                      >
                        Login
                      </span>
                    </>
                  </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Row>
    </Layout>
  );
}

export default ForgetPasswordCode;
