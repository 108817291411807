import React from 'react'
import { Row, Col, Image, Avatar, Input,  Button, Upload  } from 'antd'
import {FcAddImage} from 'react-icons/fc';

function PostHead() {
    const props = {
        action: '//jsonplaceholder.typicode.com/posts/',
        listType: 'picture',
        previewFile(file) {
          console.log('Your upload file:', file);
          // Your process logic. Here we just mock to the same file
          return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
            method: 'POST',
            body: file,
          })
            .then((res) => res.json())
            .then(({ thumbnail }) => thumbnail);
        },
      };
  return (
    <Row justify="center" gutter={30} className='info-area padding-y-30 greybg margin-y-40 border-radius-15'>
            
            <Col xs={23} md={22} xl={22}>
              
                <Row gutter={20}>
                    <Col xs={5} md={4} lg={2} xl={1}> <Avatar size={32} icon={<Image preview={false} src="./images/service1.png" />} /></Col>
                    <Col xs={19} md={20} lg={22} xl={23}>
                    <Input placeholder="Abc" className='border-radius-15' />
                    
                    </Col>
                </Row>
                <hr  className='margin-y-30' />
                <Row justify={"space-around"}>
                <Col xs={12}>
                <Upload {...props}>
    <Button className='uploadbtn' icon={<FcAddImage size={24} />}>Image Upto (5MB)</Button>
  </Upload>
                    </Col>
                    <Col xs={12} style={{textAlign:'right'}}>
                    <Button className="mainbtn padding-x-40">Post</Button> 
                    </Col>
                </Row>
            </Col>
            </Row>
  )
}

export default PostHead