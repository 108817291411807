import React from 'react'
import { Col, Row, Button, Image } from "antd";
import {FacebookOutlined,TwitterOutlined} from "@ant-design/icons"
import LogoFooter from '../../assets/logo.png'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer'>
        <Row justify='center'>
            <Col xs={22} md={20} lg={18}>
                <Row>
                  <Col xs={22} md={12} lg={9}>
                    <Image preview={false} src={LogoFooter} />
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam </p>
                    <FacebookOutlined style={{fontSize:30, color:"white"}} />
                    &emsp;
                    <TwitterOutlined  style={{fontSize:30, color:"white"}}/>
                  </Col>
                  <Col xs={22} md={12} lg={5}>
                    <h3>Quick Links</h3>
                    <ul>
                        <li><Link to="/home">Home</Link></li>
                        <li><Link to="/about">About Rituals Masonis</Link></li>
                        <li><Link to="/onlineservices">Online Community</Link></li>
                        <li><Link to="/">Supplier & Items</Link></li>
                        <li><Link to="/">VIP Messaging</Link></li>
                        <li><Link to="/premiumcontent">Premium Contents</Link></li>
                    </ul>
                  </Col>
                  <Col xs={22} md={12} lg={5}>
                  <h3>SUPPORT</h3>
                    <ul>
                        <li><Link to="/termsandconditions">Terms & Conditions</Link></li>
                        <li>Privacy Policy</li>
                        <li>Help & Faq</li>
                    </ul>
                  </Col>
                  <Col xs={22} md={12} lg={5}>
                  <h3>language</h3>
                    <ul>
                        <li>English</li>
                        <li>French</li>
                        <li>Spanish</li>
                    </ul>
                  </Col>

                  <Col className='bottom' xs={24}>
                    <p>&copy; Copyrights.All Rights Reserved.</p>
                  </Col>
                </Row>
            </Col>
        </Row>
    </div>
  )
}

export default Footer