import React from "react";
import { Row, Col } from "antd";
import Post from "../../components/post";
import PostHead from "../../components/posthead";

function NonMasonic() {
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <Row justify="center">
            <Col xs={22} md={20} xl={18}>
              <h2>Non Masonic Community</h2>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </p>
            </Col>
          </Row>
          <PostHead />
          <Post />

          <Post />

          <Post />
        </Col>
      </Row>
    </>
  );
}

export default NonMasonic;
