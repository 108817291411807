import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Image, Button } from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { ImLocation } from "react-icons/im";
import Packages from "../../components/packges";
import AliceCarousel from "react-alice-carousel";
import FeatureBook1 from "../../assets/eventdetails.png";
import FeatureBook2 from "../../assets/eventdetails.png";
import FeatureBook3 from "../../assets/eventdetails.png";

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1 },
};
const items = [
  <div className="item" data-value="1">
    <Image preview={false} src={FeatureBook1} />
  </div>,
  <div className="item" data-value="2">
    <Image preview={false} src={FeatureBook2} />
  </div>,
  <div className="item" data-value="3">
    <Image preview={false} src={FeatureBook3} />
  </div>,
  <div className="item" data-value="4">
    <Image preview={false} src={FeatureBook1} />
  </div>,
  <div className="item" data-value="5">
    <Image preview={false} src={FeatureBook2} />
  </div>,
];
function Details() {
  const [UpcomingEvenetsPage, setUpcomingEvenetsPage] = useState([
    {
      key: 1,
      image: "./images/eventdetails.png",
      title: "Dummy Online Services",
      date: "26",
      month: "june",
      year: "2023",
      locationIcon: ImLocation,
      locationName: "Songrk live, nyc",
      text: "Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ]);
  return (
    <>
      <div>
        <Row justify="center" className="whitebg">
          <Col xs={22} md={20} xl={18}>
            <Row justify="center">
              <Col xs={22} md={22} lg={22} className="">
                <h2>
                  <Link to="/upcomingevenets"><AiOutlineArrowLeft /></Link> Event Details
                </h2>
              </Col>
              <Col xs={22} md={18} lg={14} className="">
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. 
                </p>
              </Col>
            </Row>
            {UpcomingEvenetsPage.length > 0 &&
              UpcomingEvenetsPage.map((item, index) => {
                return (
                  <Row
                    justify="center"
                    className="info-area padding-y-40 margin-y-40 border-radius-15"
                    key={index}
                  >
                    <Col xs={22} md={22} lg={22} className="upcomingimage">
                      <div className="datetag">
                        <h6>{item.date}</h6>
                        <span>{item.month}</span>
                        <span>{item.year}</span>
                      </div>
                      {/* <Image preview={false} src={item.image} /> */}
                      <AliceCarousel
                        mouseTracking
                        items={items}
                        responsive={responsive}
                        disableButtonsControls={true}
                        controlsStrategy="alternate"
                        infinite
                        autoPlay
                        autoPlayInterval={800}
                      />
                    </Col>
                    <Col
                      xs={22}
                      md={22}
                      lg={22}
                      className="padding-y-40  border-radius-15"
                    >
                      <h3 className="margin-y-10">{item.title}</h3>
                      <Row>
                        <Col>
                          <span className="margin-0 goldencolor">
                            <item.locationIcon /> {item.locationName}
                          </span>
                        </Col>
                      </Row>
                      <p className="margin-y-20">{item.text}</p>
                      <p className="margin-y-20">{item.text}</p>
                      <p className="margin-y-20">{item.text}</p>
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>

        <div className="greybg">
          <Packages />
        </div>
      </div>
    </>
  );
}

export default Details;
