import React from 'react'
import Subscription from '../../components/subscription'
import { Row, Col, Image } from 'antd'
import about1 from "../../assets/about1.png"

function About() {
  return (
    <>
      <div>
        <Row justify="center"  className='whitebg'>
        <Col  xs={22} md={20} xl={18}>
            <Row justify="center"> 
            <Col  xs={22} md={20} xl={14}>
                <h2>About Rituals Masonis</h2>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua</p>
            </Col>
            </Row>
            <Row justify="center" gutter={50} className='info-area padding-y-40'>
                <Col xs={22} md={22} lg={11} >
                    <div className='relative'>
                    <Image preview={false} src={about1} />
                    <div className='abouticon'>
                    <Image preview={false} src="./images/about-icon.png" />
                    </div>
                    
                    </div>
                    
                    
                    </Col>
                <Col xs={22} md={22} lg={11}>
                    <h3>Our GOALs</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <p>It was popularised in the with the release of Letraset sheets containing.</p>
                    <p>Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                </Col>
            </Row>
        </Col>
        </Row>

        <Row justify="center" className='greybg padding-y-30' >
        <Col  xs={22} md={20} xl={18}>
            
            <Row justify="center" gutter={50} className='info-area padding-y-40'>
            <Col xs={22} md={22} lg={11}>
                    <h3>Mission & Vision</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <p>It was popularised in the with the release of Letraset sheets containing.</p>
                    <p>Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                </Col>
                <Col xs={22} md={22} lg={11} >
                <Image preview={false} src="./images/about2.png" />
                    
                    
                    </Col>
                
            </Row>
        </Col>
        </Row>

      </div>
      <Subscription/>
    </>
  )
}

export default About