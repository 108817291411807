import React, { useState } from "react";
import {
  Row,
  Col,
  Image,
  Card,
  Rate,
  Button,
  Radio,
  Typography,
  Progress,
  Form, 
  Input,
} from "antd";
import ImageGrid from "../../components/imagegrid";
import QuantitySelector from "../../components/quantity-selector";
import ReviewCaurosel from "../../components/reviewCaurosel";
import StoreProduct from '../../components/storeproduct'

function ProductDetails() {
  const images = [
    "./images/service1.png",
    "./images/upcoming4.png",
    "./images/upcoming3.png",
  ];
  const [colors, setColors] = useState(["#FF0000", "#F6D21C", "#12E119"]);
  const [size, setSize] = useState(["S", "M", "L", "XL"]);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const handleSelectedQuantityChange = (newQuantity) => {
    setSelectedQuantity(newQuantity);
  };
  const [StoreProductCards, setStoreProductrCards] = useState([
    {
      key: 1,
      image: "./images/product1.png",
      title: "Grand Lodge Past",
      ratingNumber:"10",
      price:"$180",
      rating:5
    },
    {
        key: 2,
        image: "./images/product2.png",
        title: "Grand Lodge Past",
        ratingNumber:"10",
        price:"$180",
        rating:4
      },
      {
        key: 3,
        image: "./images/product3.png",
        title: "Grand Lodge Past",
        ratingNumber:"10",
        price:"$180",
        rating:3
      },
      {
          key: 4,
          image: "./images/product4.png",
          title: "Grand Lodge Past",
          ratingNumber:"10",
          price:"$180",
          rating:3.2
        },
  ]);
  const [ratings, setRatings] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 100,
  });
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <div className="greybg padding-y-40 padding-x-30 margin-y-30">
            <Row gutter={20}>
              <Col xs={22} md={22} lg={11}>
                <ImageGrid smallImages={images} />
              </Col>
              <Col xs={22} md={22} lg={11}>
                <h3 className="margin-y-10">Men's Ruby Masonic Ring</h3>
                <div>
                  <Rate defaultValue={3} />
                  <span className="ant-rate-text">4.5 Ratings & 2 Reviews</span>
                </div>
                <span className="productprice">$200.00</span>
                <div className="colors">
                  <span>Color</span>
                  <Radio.Group size="large">
                    {colors.length > 0 &&
                      colors.map((item) => {
                        return (
                          <Radio.Button
                            value={item}
                            style={{ backgroundColor: item }}
                            className="colorRadio"
                          ></Radio.Button>
                        );
                      })}
                  </Radio.Group>
                </div>
                <div className="quantity">
                  <span>Quantity</span>

                  <QuantitySelector
                    value={selectedQuantity}
                    onChange={handleSelectedQuantityChange}
                  />
                </div>
                <div className="size">
                  <span>Select Size</span>
                  <Radio.Group size="large">
                    {size.length > 0 &&
                      size.map((item) => {
                        return (
                          <Radio.Button value={item} className="sizeRadio">
                            {item.toUpperCase()}
                          </Radio.Button>
                        );
                      })}
                  </Radio.Group>
                </div>
                <Button className="mainbtn">Add To Cart</Button>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xs={24} className="productreview">
              <h3>product specification</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s., when an unknown printer took a
                galley of type and scrambled it to make a type specimen book. It
                has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <p>
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s., when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. It has survived
                not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged.
              </p>
            </Col>

            <Col xs={24}>
              <div className="reviewarea">
                <Row gutter={20} justify={"center"}>
                  <Col xs={22} md={16}>
                    <Row>
                      <Col xs={22}>
                        <h3>REVIEWS</h3>
                      </Col>
                      <Col xs={22} md={22} lg={12}>
                        <Row>
                          <Col xs={22}>
                          <Typography.Title
                            className="fontFamily1"
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: "#BF9343",
                              textAlign: "left",
                              display:"block",
                              marginTop: 5,
                              marginBottom: 0,
                            }}
                          >
                            {4.5}
                          </Typography.Title>
                          <Rate
                            disabled
                            allowHalf
                            value={4.5}
                            style={{
                              color: "#FABF35",
                              marginTop: 0,
                              fontSize: 18,
                            }}
                          />
                          </Col>
                          
                          <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#B2B2B2",
                            textAlign: "left",
                            marginTop:10,
                            marginBottom: 20,
                          }}
                        >
                          {4.5} Ratings & {2} Reviews
                        </Typography.Title>
                        </Row>
                        
                        <Button className="mainbtn">Write Your Review</Button>
                      </Col>
                      <Col xs={22} md={22} lg={12}>
                        <Row
                          gutter={[20, 0]}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            margin: 0,
                          }}
                        >
                          <Col>
                            <Typography.Text
                              className="fontFamily1"
                              style={{
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                                fontWeight:"bold"
                              }}
                            >
                              5 Star
                            </Typography.Text>
                          </Col>

                          <Col xs={18}>
                            <Progress
                              percent={ratings[5]}
                              strokeColor={{ from: "#FABF35", to: "#FABF35" }}
                              style={{ width: "100%", color: "#FABF35" }}
                            />
                          </Col>
                        </Row>
                        <Row
                          gutter={[20, 0]}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            margin: 0,
                          }}
                        >
                          <Col>
                            <Typography.Text
                              className="fontFamily1"
                              style={{
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                                fontWeight:"bold"
                              }}
                            >
                              4 Star
                            </Typography.Text>
                          </Col>

                          <Col xs={18}>
                            <Progress
                              percent={ratings[4]}
                              strokeColor={{ from: "#FABF35", to: "#FABF35" }}
                              style={{ width: "100%", color: "#FABF35" }}
                            />
                          </Col>
                        </Row>
                        <Row
                          gutter={[20, 0]}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            margin: 0,
                          }}
                        >
                          <Col>
                            <Typography.Text
                              className="fontFamily1"
                              style={{
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                                fontWeight:"bold"
                              }}
                            >
                              3 Star
                            </Typography.Text>
                          </Col>

                          <Col xs={18}>
                            <Progress
                              percent={ratings[3]}
                              strokeColor={{ from: "#FABF35", to: "#FABF35" }}
                              style={{ width: "100%", color: "#FABF35" }}
                            />
                          </Col>
                        </Row>
                        <Row
                          gutter={[20, 0]}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            margin: 0,
                          }}
                        >
                          <Col>
                            <Typography.Text
                              className="fontFamily1"
                              style={{
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                                fontWeight:"bold"
                              }}
                            >
                              2 Star
                            </Typography.Text>
                          </Col>

                          <Col xs={18}>
                            <Progress
                              percent={ratings[2]}
                              strokeColor={{ from: "#FABF35", to: "#FABF35" }}
                              style={{ width: "100%", color: "#FABF35" }}
                            />
                          </Col>
                        </Row>
                        <Row
                          gutter={[20, 0]}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            margin: 0,
                          }}
                        >
                          <Col>
                            <Typography.Text
                              className="fontFamily1"
                              style={{
                                fontSize: "14px",
                                color: "#000",
                                textAlign: "left",
                                fontWeight:"bold"
                              }}
                            >
                              1 Star
                            </Typography.Text>
                          </Col>

                          <Col xs={18}>
                            <Progress
                              percent={ratings[1]}
                              strokeColor={{ from: "#FABF35", to: "#FABF35" }}
                              style={{ width: "100%", color: "#FABF35" }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={22}>
                    <ReviewCaurosel/>
                  </Col>

                  <Col xs={22}>
                    <h5>Post a Comment</h5>
                    <Form.Item>
                      <Input.TextArea placeholder="Your Message Here!" />
                    </Form.Item>
                  </Col>
                    <Col xs={22} style={{textAlign:"center"}}>
                      <Button className="mainbtn padding-x-40">Post</Button>
                    </Col>
                  
                </Row>
              </div>
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col xs={22} md={18} lg={16}>
              <h2>Recommended Products</h2>
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
            </Col>
          </Row>
          <Row justify="center" gutter={20}>
                <StoreProduct StoreProductCards={StoreProductCards}/>
                
            </Row>
        </Col>
      </Row>
    </>
  );
}

export default ProductDetails;
