import React from 'react'
import { Row, Col, Card, Image, Button, Input, Space } from 'antd'
import Logo from '../../assets/logo.png'
import { useNavigate} from "react-router-dom";

function FirstDegree() {
    const navigate = useNavigate();
  return (
    <>
    <div>
    <Row justify="center"  className='whitebg'>
    <Col  xs={22} md={20} xl={18}>
        <Row justify="center"> 
        <Col  xs={22} md={20} xl={14}>
            <Card className='reviewarea text-center' >
                <h3>First Degree</h3>
                <h6 className='mousepointer' onClick={() => navigate("/degree-details")}>#1ARMNP</h6>
                <p>Lorem Ipsum is simply dummy </p>
                <div>
                <Space.Compact
      style={{
        width: '100%',
        marginBottom:"25px"
      }}
    >
      <Input defaultValue="Your PCS Here" />
      <Button type="primary" className='mainbtn'>Submit</Button>
    </Space.Compact>
                </div>
                <Image
width={150}
src={Logo} preview={false}
/>

<p style={{marginTop:"50px"}}>Lorem <span className='goldencolor'>#1ARMNP</span> Ipsum is simply dummy </p>
<p >Lorem Ipsum is simply dummy </p>
<span>Ipsum is simply dummy<br/>simply dummy</span>
            </Card>
        </Col>
        </Row>
       
    </Col>
    </Row>
    </div>
</>
  )
}

export default FirstDegree