import React from 'react'
import { Col, Row, Button, Image } from "antd";
import Ibcon1 from '../../assets/libraryicon.png'
import Ibcon2 from '../../assets/vipmsgicon.png'
import Ibcon3 from '../../assets/advericon.png'
import Ibcon4 from '../../assets/membericon.png'

function RightsArea() {
  return (
    <div className='rightarea'>
        <Row  justify="center"> 
            <Col xs={22} md={20} xl={18}>
                <Row>
                    <Col xs={14}><h2>You have to know us right? we have</h2></Col>
                    <Col xs={24} md={12}>
                        <Row className='iconarea'>
                            <Col xs={4} md={3} xl={2}>
                                <Image preview={false} src={Ibcon1} />
                            </Col>
                            <Col xs={20} md={20} xl={20}>
                                <h4>Library Access</h4>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonum</p>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} md={12}>
                        <Row className='iconarea'>
                            <Col xs={4} md={3} xl={2}>
                                <Image preview={false} src={Ibcon2} />
                            </Col>
                            <Col xs={20} md={20} xl={20}>
                                <h4>VIP Messaging</h4>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonum</p>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} md={12}>
                        <Row className='iconarea'>
                            <Col xs={4} md={3} xl={2}>
                                <Image preview={false} src={Ibcon3} />
                            </Col>
                            <Col xs={20} md={20} xl={20}>
                                <h4>Advertise Business</h4>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonum</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={12}>
                        <Row className='iconarea'>
                            <Col xs={4} md={3} xl={2}>
                                <Image preview={false} src={Ibcon4} />
                            </Col>
                            <Col xs={20} md={20} xl={20}>
                                <h4>Member Community</h4>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonum</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
  )
}

export default RightsArea