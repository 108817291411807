import React from 'react'
import { Col, Row, Button, Image } from "antd";
import VideoImg from '../../assets/video.png'

function VideoArea() {
  return (
    <div>
        <Row justify="center" className='videoarea'>
            <Col xs={22} md={20} xl={18}>
                <Row>
                <Col xs={22} md={12}>
                    <h2>Join our community</h2>
                    <p>Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores Et Ea Rebum. </p>
                    <Button className="mainbtn">Join Now</Button>
                </Col>
                <Col xs={22} md={12}>
                <Image preview={false} src={VideoImg} />
                </Col>
                </Row>
            </Col>
        </Row>
        
    </div>
  )
}

export default VideoArea