import React from "react";
import {
  Row,
  Col,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Select,
  Space,
  Checkbox,
  Card,
  Table,
  Image,
  Divider,
  InputNumber,
  message,
  Upload,
} from "antd";

import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { InboxOutlined } from "@ant-design/icons";
import swal from "sweetalert";

function ContactUs() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const handleSubmit = () => {
    // setIsModalOpen(false);
    swal(
      "System Message!",
      "Your Ad Has Been Submitted To The Admin! You'll Be Notified Whether It Is Approved Or Disapproved..","Okay"
    );
    
  };
  const onFinish = (values) => {
    console.log("Success:", values);
    return;
  };
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={22} xl={20}>
          <Row justify="center">
            <Col xs={22} md={20} xl={18}>
              <h2>
                
              Contact Us
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua
              </p>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={22} md={20} xl={16}>
              <div className="shadowwhitebg padding-x-30 padding-y-40 margin-0 margin-y-40 border-radius-15">
                <div className="authFormBox">
                  <Row style={{ width: "100%", justifyContent: "center" }}>
                    <Col xs={24} md={24} className="formWrap">
                      <br />
                      <Form
                        layout="vertical"
                        name="basic"
                        className="loginForm"
                        labelCol={{
                          span: 0,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        initialValues={{
                          remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                      >
                        <Form.Item
                          label="Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Name",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter  Name"
                            className="AuthFormInput"
                          />
                        </Form.Item>

                        <Form.Item
                          label="Email Address"
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "Enter Valid Email Address",
                            },
                            {
                              required: true,
                              message: "Enter Email Address",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Email Address"
                            className="AuthFormInput"
                          />
                        </Form.Item>

                        <Form.Item
                          label="Message"
                          name="message*"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input.TextArea placeholder="Enter Message Here..." />
                        </Form.Item>

                        

                        <br />

                        <Form.Item style={{ textAlign: "center" }}>
                          <Button
                            onClick={() => handleSubmit()}
                            type="primary"
                            htmlType="submit"
                            className="loginButton mainbtn"
                          >
                            {loading ? "Loading..." : "Submit Now!"}
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default ContactUs;
