import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Card,
  Input,
  Modal,
  Button,
  Popover,
  Layout,
  Checkbox,
  Skeleton,
  Table,
  Spin,
  Select,
  Image,
  Pagination,
  DatePicker,
  message,
} from "antd";
import dayjs from "dayjs";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FaSearch, FaFilter, FaCaretDown, FaEye } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";

import { Get } from "../../config/api/get";
import { ORDER, USERS } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function OrderHistory() {
  const token = useSelector((state) => state.user.userToken);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [orders, setOrders] = useState([
    {
      _id: 1,
      orderId: "ORDER_001_4567",
      createdAt: new Date(),
      amount: 1000,
      status: "PENDING",
    },
    {
      _id: 2,
      orderId: "ORDER_001_4568",
      createdAt: new Date(),
      amount: 500,
      status: "INPROCESS",
    },
    {
      _id: 3,
      orderId: "ORDER_001_4578",
      createdAt: new Date(),
      amount: 587,
      status: "DISPATCHED",
    },
    {
      _id: 4,
      orderId: "ORDER_001_4567",
      createdAt: new Date(),
      amount: 660,
      status: "DELIEVRED",
    },
    {
      _id: 5,
      orderId: "ORDER_001_4567",
      createdAt: new Date(),
      amount: 750,
      status: "DELIEVRED",
    },
  ]);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    status: null,
    type: null,
    keyword: "",
    from: null,
    to: null,
  });

  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = `Showing records ${endIndex} of ${paginationConfig.totalDocs}`;

  useEffect(() => {
    getOrders();
  }, []);

  console.log(orders, "orders");

  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });

    getOrders(pageNumber);
  };

  const handleSearch = (value) => {
    setFilter({
      ...filter,
      keyword: value,
    });
  };

  const handleStatusChange = (value) => {
    setFilter({
      ...filter,
      status: value,
    });
  };

  const handleTypeChange = (value) => {
    setFilter({
      ...filter,
      type: value,
    });
  };

  const resetFilter = () => {
    setFilter({
      status: null,
      keyword: "",
      from: null,
      to: null,
    });
    getOrders(paginationConfig.pageNumber, paginationConfig.limit, "", true);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleFrom = (date) => {
    setFilter({
      ...filter,
      from: date,
    });
  };

  const handleTo = (date) => {
    setFilter({
      ...filter,
      to: date,
    });
  };

  const handleLimitChange = (pageSize) => {
    setPaginationConfig({
      ...paginationConfig,
      limit: pageSize,
      current: 1,
    });

    getOrders(1, pageSize);
  };

  const getOrders = async (pageNumber, pageSize, search, reset = false) => {
    //   setLoading(true);
    try {
      // const response = await Get(ORDER.getOrderLogs, token, {
      //   page: pageNumber
      //     ? pageNumber.toString()
      //     : paginationConfig.pageNumber.toString(),
      //   limit: pageSize
      //     ? pageSize.toString()
      //     : paginationConfig.limit.toString(),
      //   status: reset ? "" : filter.status || null,
      //   type: reset ? "" : filter.type || null,
      //   keyword: search ? search : null,
      //   from: reset ? "" : filter?.from ? filter?.from.toISOString() : "",
      //   to: reset ? "" : filter?.to ? filter?.to.toISOString() : "",
      // });
      // setLoading(false);
      // console.log("response", response);
      // if (response?.status) {
      //   setOrders(response?.data?.docs);
      //   setPaginationConfig({
      //     pageNumber: response?.data?.page,
      //     limit: response?.data?.limit,
      //     totalDocs: response?.data?.totalDocs,
      //     totalPages: response?.data?.totalPages,
      //   });
      // } else {
      //   message.error(response.message);
      //   console.log("error====>", response);
      // }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  console.log("paginationConfig", paginationConfig);

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const columns = [
    {
      title: "S. No.	",
      dataIndex: "key",
      key: "key",
      width: 100,
      render: (value, item, index) => (index < 9 && "0") + (index + 1),
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => <span>{dayjs(item).format("M/D/YYYY")}</span>,
    },
    {
      title: "Amount Paid",
      dataIndex: "amount",
      key: "amount",
      render: (item) => <span>${item}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item) => <>{item=="PENDING" ? <span style={{color:"#ED8D37"}}>{item}</span> : item=="INPROCESS" ? <span  style={{color:"#28BCBC"}}>{item}</span>: item=="DELIEVRED" ? <span style={{color:"#00D640"}}>{item}</span>: item=="DISPATCHED" ? <span style={{color:"#892BF5"}}>{item}</span>: <span>{item}</span>}</>
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (value, item, index) => (
        <AiOutlineEye
          style={{ fontSize: "18px", color: "grey", cursor: "pointer" }}
          onClick={() => navigate("/profile/order-detail/" + value , {state:item.status})}
        />
      ),
    },
  ];

  const filterContent = (
    <div className="filterDropdown">
      <div>
        <p
          className="mainLabel"
          style={{ padding: "10px", fontSize: "18px", fontWeight: "bold" }}
        >
          Filter
        </p>
      </div>
      <hr style={{ marginBottom: 10 }} />

      <div className="filterDropdownBody">
        <p
          className="mainLabel"
          style={{ fontWeight: "bold", marginBottom: 10 }}
        >
          Sort By:
        </p>
        <DatePicker
          className="mainInput filterInput"
          value={filter.from}
          onChange={(e) => handleFrom(e)}
          placeholder="From"
        />
        <DatePicker
          className="mainInput filterInput"
          value={filter.to}
          onChange={(e) => handleTo(e)}
          placeholder="To"
        />

        <p
          className="mainLabel"
          style={{ fontWeight: "bold", marginBottom: 10 }}
        >
          Status:
        </p>

        <Select
          size={"large"}
          className="filterSelectBox"
          placeholder="Select Status"
          value={filter.status}
          onChange={(e) => handleStatusChange(e)}
          style={{
            width: "100%",
            marginBottom: "10px",
            textAlign: "left",
          }}
          options={[
            { value: "PENDING", label: "Pending" },
            { value: "INPROCESS", label: "In-Process" },
            { value: "DELIEVRED", label: "Delieverd" },
            { value: "DISPATCHED", label: "Dispatched" },
          ]}
        />

        <Button
          type="primary"
          block
          size={"large"}
          style={{ marginBottom: "10px" }}
          className="mainbtn"
          onClick={() => getOrders()}
        >
          Apply
        </Button>
        <Button
          type="primary"
          block
          size={"large"}
          className="mainbtn"
          onClick={() => resetFilter()}
        >
          Clear All
        </Button>
      </div>
    </div>
  );
  return (
    <>
      <Row justify="center">
        <Col xs={24} md={24} xl={24}>
          <h4 style={{ textAlign: "center" }}>Order & History</h4>
          <Card>
            <div className="boxDetails">
              <Row style={{ padding: "10px 20px" }}>
                <Col xs={24} md={12}></Col>
                <Col
                  xs={24}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Popover
                    content={filterContent}
                    trigger="click"
                    open={open}
                    onOpenChange={handleOpenChange}
                    placement="bottomRight"
                    arrow={false}
                  >
                    <Button
                      shape="circle"
                      style={{
                        padding: "12px 12px 5px",
                        height: "auto",
                        backgroundColor: "#BF9343",
                      }}
                    >
                      <FaFilter style={{ fontSize: "16px", color: "white" }} />
                    </Button>
                  </Popover>
                  &emsp;
                  <Input
                    style={{ width: "250px" }}
                    className="mainInput dashInput"
                    placeholder="Search Here"
                    onChange={(e) => handleSearch(e.target.value)}
                    suffix={
                      <FaSearch
                        style={{
                          color: "grey",
                          fontSize: 16,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          getOrders(1, paginationConfig.limit, filter.keyword)
                        }
                      />
                    }
                    onPressEnter={(e) =>
                      getOrders(1, paginationConfig.limit, filter.keyword)
                    }
                  />
                </Col>
              </Row>

              <Row style={{ padding: 20, overflow: "auto" }}>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Skeleton active />
                    <br />
                  </div>
                ) : (
                  <Table
                    className="styledTable"
                    dataSource={orders}
                    columns={columns}
                    pagination={false}
                  />
                )}
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Col xs={24} md={12}>
                  <p
                    className="fontFamily1"
                    style={{
                      color: "#999",
                      fontSize: "16px",
                      textAlign: "left",
                    }}
                  >
                    {message}
                  </p>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Pagination
                    className="styledPagination"
                    onChange={(e) => handlePageChange(e)}
                    current={parseInt(paginationConfig.pageNumber)}
                    pageSize={paginationConfig.limit}
                    total={paginationConfig.totalDocs}
                    itemRender={itemRender}
                  />
                </Col>
              </Row>
              <br />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default OrderHistory;
