import React from "react";
// import AuthLayout from "../../components/";
import { Link } from "react-router-dom";
import { Col, Row, Layout } from "antd";
import Packages from "../../components/packges";

// import router from "next/router";

function LoginSubscription() {
  return (
    <Layout className="banner">
      <Row className="flex" style={{ minHeight: "100vh" }}>
        <Col xs={24}>
          <Packages />

          <Link to={"/login"}>Skip For Now!</Link>
        </Col>
      </Row>
    </Layout>
  );
}

export default LoginSubscription;
