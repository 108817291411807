import React from 'react'
import { Row, Col } from "antd";
import { useNavigate} from "react-router-dom";

function LibraryContentFirstRankTopic() {
    const navigate = useNavigate();
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <Row justify="center">
            <Col xs={22} md={20} xl={18} className='text-center'>
              <h4>Welcome to Topic Menu for Masonic Ritual of Apprentice</h4>
              <p>Please click on, the topic of your choice from 1 to 9
              </p>
            </Col>
            <Col xs={22} md={20} xl={18}>
              <div className='topics mousepointer' onClick={() => navigate("/library-content-details")}>
                <p>The Works of the Temple Masonic</p>
                <span>1ARM-TTM</span>
              </div>
              <div className='topics mousepointer' onClick={() => navigate("/library-content-details")}>
                <p>Opening of Masonic Temple</p>
                <span>1ARM-TTM</span>
              </div>

              <div className='topics mousepointer' onClick={() => navigate("/library-content-details")}>
                <p>The plots of the long square</p>
                <span>1ARM-TTM</span>
              </div>

              <div className='topics mousepointer' onClick={() => navigate("/library-content-details")}>
                <p>Installation of a Venerable Master</p>
                <span>1ARM-TTM</span>
              </div>

              <div className='topics mousepointer' onClick={() => navigate("/library-content-details")}>
                <p>Masonic initiation</p>
                <span>1ARM-TTM</span>
              </div>

              <div className='topics mousepointer' onClick={() => navigate("/library-content-details")}>
                <p>Apprenticeship Instruction</p>
                <span>1ARM-TTM</span>
              </div>

              <div className='topics mousepointer' onClick={() => navigate("/library-content-details")}>
                <p>Preamble of Freemasonry</p>
                <span>1ARM-TTM</span>
              </div>

              <div className='topics mousepointer' onClick={() => navigate("/library-content-details")}>
                <p>Decorations of the Masonic Temple</p>
                <span>1ARM-TTM</span>
              </div>

              <div className='topics mousepointer' onClick={() => navigate("/library-content-details")}>
                <p>Closure of Work</p>
                <span>1ARM-TTM</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default LibraryContentFirstRankTopic