import React, {useState} from 'react'
import { Row, Col} from 'antd'
import BannerIcons from '../../components/banner-icons'


function LibararyContent() {
    const [bannerCards, setBannerCards] = useState([
        {
          key: 1,
          image: "./images/banner-icon-7.png",
          title: "Religious Articles",
          link:"/glory",
          des: "abc",
        },
        {
          key: 2,
          image: "./images/banner-icon-4.png",
          title: "Religious Articles",
          link:"/glory",
          des: "abc",

        },
        {
          key: 3,
          image: "./images/banner-icon-8.png",
          title: "Religious Articles",
          link:"/glory",
          des: "abc",
        },
        {
          key: 4,
          image: "./images/banner-icon-6.png",
          title: "Religious Articles",
          link:"/glory",
          des: "abc",
        },
        {
            key: 5,
            image: "./images/banner-icon-7.png",
            title: "Religious Articles",
            link:"/glory",
            des: "abc",
          },
          {
            key: 6,
            image: "./images/banner-icon-4.png",
            title: "Religious Articles",
          link:"/glory",
          des: "abc",
          },
          {
            key: 7,
            image: "./images/banner-icon-8.png",
            title: "Religious Articles",
          link:"/glory",
          des: "abc",
          },
          {
            key: 8,
            image: "./images/banner-icon-6.png",
            title: "Religious Articles",
          link:"/glory",
          des: "abc",
          },
          {
            key: 9,
            image: "./images/banner-icon-7.png",
            title: "Religious Articles",
          link:"/glory",
          des: "abc",
          },
          {
            key: 10,
            image: "./images/banner-icon-4.png",
            title: "Religious Articles",
          link:"/glory",
          des: "abc",
          },
          {
            key: 11,
            image: "./images/banner-icon-8.png",
            title: "Religious Articles",
          link:"/glory",
          des: "abc",
          },
          {
            key: 12,
            image: "./images/banner-icon-6.png",
            title: "Religious Articles",
          link:"/glory",
          des: "abc",
          },
      ]);
  return (
    <div>
         <Row justify="center"  className='whitebg'>
        <Col  xs={22} md={20} xl={18}>
            <Row justify="center"> 
            <Col  xs={22} md={20} xl={14}>
                <h2>Libarary Content</h2>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua</p>
            </Col>
            </Row>
            <Row justify="center" gutter={50} className='padding-y-40'>
                
                <Col xs={22} md={22} lg={22}>
                   <BannerIcons bannerCards={bannerCards} bannerIconClass="bannercards3"/>

                   
                </Col>
            </Row>
        </Col>
        </Row>
        
    </div>
  )
}

export default LibararyContent