import React from 'react'
import { Col, Row, Button, Image } from "antd";
// import AliceCarousel from 'react-alice-carousel';



const responsive = {
  0: { items: 1 },
  568: { items: 3},
  1024: { items: 5 },
};
const items = [
  <div className="item" data-value="1"><Image preview={false} src="./images/bannerimage1.png" /></div>,
  <div className="item" data-value="2"><Image preview={false} src="./images/bannerimage2.png" /></div>,
  <div className="item" data-value="3"><Image preview={false} src="./images/bannerimage3.png" /></div>,
  <div className="item" data-value="4"><Image preview={false} src="./images/bannerimage4.png" /></div>,
  <div className="item" data-value="5"><Image preview={false} src="./images/bannerimage5.png" /></div>,
  <div className="item" data-value="2"><Image preview={false} src="./images/bannerimage2.png" /></div>,
  <div className="item" data-value="3"><Image preview={false} src="./images/bannerimage3.png" /></div>,
  <div className="item" data-value="4"><Image preview={false} src="./images/bannerimage4.png" /></div>,
  <div className="item" data-value="5"><Image preview={false} src="./images/bannerimage5.png" /></div>,
];

function Banner({}) {
  return (
    <div className='banner'>
      
      <Row justify="center">
        <Col xs={22} md={20} xl={14}>
        <h1>RitualMasonic1</h1>
      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. at vero eos et accusam et justo duo dolores et ea rebum. stet clita kasd gubergren, no sea takimata sanctus est lorem ipsum dolor sit amet. lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
      <Button className="mainbtn">Get Started</Button>
        </Col>
      </Row>
      {/* <Row justify="center">
        <Col xs={22}  xl={20}>
        <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        disableButtonsControls={true}
        disableDotsControls
        controlsStrategy="alternate"
        infinite
        autoPlay
        autoPlayInterval={800}
    />
        </Col>
      </Row> */}
    </div>
  )
}

export default Banner