import React from 'react'
import { Col, Row, Image, Button } from "antd";
import AliceCarousel from 'react-alice-carousel';
import FeatureBook1 from '../../assets/featuredbooks1.png'
import FeatureBook2 from '../../assets/featuredbooks2.png'
import FeatureBook3 from '../../assets/featuredbooks3.png'
import { useNavigate } from 'react-router-dom';


const responsive = {
    0: { items: 1 },
    568: { items: 3},
    1024: { items: 3 },
  };
  const items = [
    <div className="item" data-value="1">
        <Image preview={false} src={FeatureBook1} />
        <h3>ashley & jaquavis</h3>
        <span>read more </span>
        </div>,
    <div className="item" data-value="2">
    <Image preview={false} src={FeatureBook2} />
        <h3>Sixty six lawa</h3>
        <span>read more </span> 
    </div>,
    <div className="item" data-value="3">
        <Image preview={false} src={FeatureBook3}  />
        <h3>facts & fictions</h3>
        <span>read more </span>
        </div>,
    <div className="item" data-value="4">
        <Image preview={false} src={FeatureBook1}  />
        <h3>ashley & jaquavis</h3>
        <span>read more </span>
    </div>,
    <div className="item" data-value="5">
        <Image preview={false} src={FeatureBook2}  />
        <h3>Sixty six lawa</h3>
        <span>read more </span> 
        </div>,
  ];


function FeaturedBooks() {
  const navigate = useNavigate();
    
  return (
    <div className='featuredarea'>
        <Row justify='center'>
            <Col xs={22} md={18} lg={18}>
                <Row  justify='center'>
                <Col xs={22} md={18} lg={14} className=''>
                    <h2>Featured Books and Masonic Rituals</h2>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
                </Col>
                <Col xs={24}>
                <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        disableButtonsControls={true}
        disableDotsControls
        controlsStrategy="alternate"
        infinite
        autoPlay
        autoPlayInterval={800}
    />
                </Col>
                <Col><Button className="mainbtn" onClick={()=>{navigate("/masonicbooks")}}>View All</Button></Col>
                </Row>
            </Col>
        </Row>
    </div>
  )
}

export default FeaturedBooks