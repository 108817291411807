import React, {useState} from 'react'
import { Row, Col, Image,Button } from 'antd'

import {ImLocation} from 'react-icons/im';
import Packages from '../../components/packges';
import { useNavigate } from 'react-router-dom';



function UpcomingEvenetsPage() { 
    const navigate= useNavigate()
    const [UpcomingEvenetsPage, setUpcomingEvenetsPage] = useState([
        {
          key: 1,
          image: "./images/upcoming1.png",
          title: "Dummy Online Services",
          date:"26",
          month:"june",
          year:"2023",
          locationIcon: ImLocation,
          locationName:"Songrk live, nyc",
          text:"Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
          bottomBtn:"View Details",
          onclick:() =>{
            navigate("/upcomingeventdetails")
          }

        },
        {
            key: 1,
            image: "./images/upcoming2.png",
            title: "Dummy Online Services",
            date:"26",
            month:"june",
            year:"2023",
            locationIcon: ImLocation,
            locationName:"Songrk live, nyc",
            text:"Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            bottomBtn:"View Details",
            onclick:() =>{
                navigate("/upcomingeventdetails")
              }
  
          },
          {
            key: 1,
            image: "./images/upcoming3.png",
            title: "Dummy Online Services",
            date:"26",
            month:"june",
            year:"2023",
            locationIcon: ImLocation,
            locationName:"Songrk live, nyc",
            text:"Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            bottomBtn:"View Details",
            onclick:() =>{
                navigate("/upcomingeventdetails")
              }
  
          },
          {
            key: 1,
            image: "./images/upcoming4.png",
            title: "Dummy Online Services",
            date:"26",
            month:"june",
            year:"2023",
            locationIcon: ImLocation,
            locationName:"Songrk live, nyc",
            text:"Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            bottomBtn:"View Details",
            onclick:() =>{
                navigate("/upcomingeventdetails")
              }
  
          },

      ]);
  return (
   <>
    <div>
        <Row justify="center"  className='whitebg'>
        <Col  xs={22} md={20} xl={18}>
            <Row justify="center"> 
            <Col  xs={22} md={20} xl={14}>
                <h2>Upcoming Events</h2>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua</p>
            </Col>
            </Row>
            {UpcomingEvenetsPage.length > 0 &&
              UpcomingEvenetsPage.map((item, index) => {
                return( <Row justify="center" className='info-area padding-y-40 margin-y-40 border-radius-15' key={index}>
                <Col xs={22} md={22} lg={11} className='upcomingimage' >
                    <div className='datetag'>
                        <h6>{item.date}</h6>
                        <span>{item.month}</span>
                        <span>{item.year}</span>
                    </div>
                <Image preview={false} src={item.image} />
                    
                    
                    </Col>
                <Col xs={22} md={22} lg={11} className='shadowwhitebg padding-y-40  padding-x-40  border-radius-15'>
                    <h3 className='margin-y-10'>{item.title}</h3>
                    <Row>
                        
                        <Col>
                            <span className='margin-0 goldencolor'><item.locationIcon/> {item.locationName}</span>
                        </Col>
                    </Row>
                    <p className='margin-y-20'>{item.text}</p>
                    <Button className="mainbtn" onClick={item.onclick}>{item.bottomBtn}</Button>
                </Col>
            </Row>);
               
              })}
            
        </Col>
    
        
       
        </Row>

        <Packages/> 

      </div>
   </>
  )
}

export default UpcomingEvenetsPage