import React from 'react'
import { Col, Row, Image } from "antd";
import AliceCarousel from 'react-alice-carousel';
import Event1 from '../../assets/event1.png'
import Event2 from '../../assets/event2.png'
import Event3 from '../../assets/event3.png'
import Event4 from '../../assets/event4.png'
import Event5 from '../../assets/event5.png'
import Event6 from '../../assets/event6.png'


const responsive = {
    0: { items: 1 },
    568: { items: 3},
    1024: { items: 6 },
  };
  const items = [
    <div className="item" data-value="1">
        <Image preview={false} src={Event1} />
        <h3>David Guetta</h3>
        <p>Songrk live, nyc</p>
        <span>11 April, 2023</span>
        </div>,
    <div className="item" data-value="2">
    <Image preview={false} src={Event2} />
        <h3>David Guetta</h3>
        <p>Songrk live, nyc</p>
        <span>11 April, 2023</span>
    </div>,
    <div className="item" data-value="3">
        <Image preview={false} src={Event3} />
        <h3>David Guetta</h3>
        <p>Songrk live, nyc</p>
        <span>11 April, 2023</span>
        </div>,
    <div className="item" data-value="4">
        <Image preview={false} src={Event4} />
        <h3>David Guetta</h3>
        <p>Songrk live, nyc</p>
        <span>11 April, 2023</span>
    </div>,
    <div className="item" data-value="5">
        <Image preview={false} src={Event5} />
        <h3>David Guetta</h3>
        <p>Songrk live, nyc</p>
        <span>11 April, 2023</span>
        </div>,<div className="item" data-value="1">
        <Image preview={false} src={Event1} />
        <h3>David Guetta</h3>
        <p>Songrk live, nyc</p>
        <span>11 April, 2023</span>
        </div>,
    <div className="item" data-value="2">
    <Image preview={false} src={Event2} />
        <h3>David Guetta</h3>
        <p>Songrk live, nyc</p>
        <span>11 April, 2023</span>
    </div>,
    <div className="item" data-value="3">
        <Image preview={false} src={Event3} />
        <h3>David Guetta</h3>
        <p>Songrk live, nyc</p>
        <span>11 April, 2023</span>
        </div>,
    <div className="item" data-value="4">
        <Image preview={false} src={Event4} />
        <h3>David Guetta</h3>
        <p>Songrk live, nyc</p>
        <span>11 April, 2023</span>
    </div>,
    <div className="item" data-value="5">
        <Image preview={false} src={Event5} />
        <h3>David Guetta</h3>
        <p>Songrk live, nyc</p>
        <span>11 April, 2023</span>
        </div>,
  ];

function UpcomingEvenets() {
  return (
    <div  className='eventsarea'>
        <Row justify='center'>
                <Col xs={22} md={20}>
                    <h2>Upcoming Events Masonic and community</h2>
                </Col>
                <Col xs={24}>
                <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        disableButtonsControls={true}
        disableDotsControls
        controlsStrategy="alternate"
        paddingLeft={50}
        paddingRight={50}
        infinite
        autoPlay
        autoPlayInterval={800}
    />
                </Col>
        </Row>
    </div>
  )
}

export default UpcomingEvenets