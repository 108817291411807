import React, { useState } from 'react'
import { Row, Col,  List, Image } from "antd";
import image1 from "../../assets/product4.png"

const count = 3;

function ConfirmOrderDetails() {



  const [list, setList] = useState([]);


    const [orderDetails, setOrderDetails] = useState([
          {
            key:1,
            image:image1,
            productName:"Abc Product",
            amount:100

          },
          {
            key:2,
            image:image1,
            productName:"Abc Product",
            amount:200

          },
          {
            key:3,
            image:image1,
            productName:"Abc Product",
            amount:150

          },
      ]
    )
  return (
    <div className="checkout">
      <h3>ConfirmOrderDetails</h3>
        <Row>
          <Col xs={24} md={24}>
          <List
      className="demo-loadmore-list"
      itemLayout="horizontal"
     
      dataSource={orderDetails}
      renderItem={(item) => (
        <List.Item
          
        >
          <Row gutter={20}>
            <Col><Image preview={false} src={item.image} width={"60px"} height={"60px"} style={{objectFit:"contain"}} /></Col>
            <Col><h6>{item.productName}</h6></Col>
            <Col><h5>$ {item.amount}</h5></Col>
          </Row>
            
           
        </List.Item>
      )}
    />
          </Col>
        </Row>
      </div>
  )
}

export default ConfirmOrderDetails