import React from "react";
import { Col, Row, Image, Rate, Avatar } from "antd";
import AliceCarousel from "react-alice-carousel";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
const items = [
  <div className="item" data-value="1">
    <div className="clientreview ">
    <Rate allowHalf defaultValue={4.5} />
    <h3>Lorum Ipsum Dummy</h3>
    <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
    <Row gutter={20} className="margin-y-15">
      <Col>
        <Avatar
          size={48}
          icon={<Image preview={false} src="./images/profileicon.png" />}
        />
      </Col>
      <Col>
        <h4>David Guetta</h4>
        <span>Songrk live, nyc</span>
      </Col>
    </Row>
    </div>
    
    
  </div>,
  <div className="item" data-value="1">
  <div className="clientreview ">
  <Rate allowHalf defaultValue={4.5} />
  <h3>Lorum Ipsum Dummy</h3>
  <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
  <Row gutter={20} className="margin-y-15">
    <Col>
      <Avatar
        size={48}
        icon={<Image preview={false} src="./images/profileicon.png" />}
      />
    </Col>
    <Col>
      <h4>David Guetta</h4>
      <span>Songrk live, nyc</span>
    </Col>
  </Row>
  </div>
  
  
</div>,
<div className="item" data-value="1">
    <div className="clientreview ">
    <Rate allowHalf defaultValue={4.5} />
    <h3>Lorum Ipsum Dummy</h3>
    <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
    <Row gutter={20} className="margin-y-15">
      <Col>
        <Avatar
          size={48}
          icon={<Image preview={false} src="./images/profileicon.png" />}
        />
      </Col>
      <Col>
        <h4>David Guetta</h4>
        <span>Songrk live, nyc</span>
      </Col>
    </Row>
    </div>
    
    
  </div>,
  <div className="item" data-value="1">
  <div className="clientreview ">
  <Rate allowHalf defaultValue={4.5} />
  <h3>Lorum Ipsum Dummy</h3>
  <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
  <Row gutter={20} className="margin-y-15">
    <Col>
      <Avatar
        size={48}
        icon={<Image preview={false} src="./images/profileicon.png" />}
      />
    </Col>
    <Col>
      <h4>David Guetta</h4>
      <span>Songrk live, nyc</span>
    </Col>
  </Row>
  </div>
  
  
</div>,
];

function ReviewCaurosel() {
  return (
    <div>
      <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        disableButtonsControls={true}
        disableDotsControls
        controlsStrategy="alternate"
        paddingLeft={0}
        paddingRight={0}
        infinite
        autoPlay
        autoPlayInterval={800}
      />
    </div>
  );
}

export default ReviewCaurosel;
