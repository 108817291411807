import React from "react";
import { Row, Col } from "antd";
import Post from "../../components/post";

function Articles() {
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <Row justify="center">
            <Col xs={22} md={20} xl={14}>
              <h2>Masonic Articles</h2>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. 
              </p>
            </Col>
          </Row>
          <Post />

          <Post />

          <Post />
        </Col>
      </Row>
    </>
  );
}

export default Articles;
