import React, { useState } from "react";
import { Col, Row, Menu, Image, Button, Popover,Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import {HiShoppingCart,HiBell} from 'react-icons/hi';
import {BiSolidMessageRounded, BiSearch} from 'react-icons/bi';
import { useNavigate,Link } from "react-router-dom";
import Logo from '../../assets/logo.png'
import ProfileIcon from '../../assets/profileicon.png'
const items = [
  {
    label: "Home",
    key: "/home",
  },
  {
    label: "About Rituals Masonis",
    key: "/about",
  },
  {
    label: "Online Services",
    key: "/onlineservices",
  },
  {
    label: "Masonic and Religious articles",
    key: "/articles",
  },
  {
    label: "Community non-mason access",
    key: "/nonmasoniccomunity",
  },
  // {
  //   label: "VIP messaging",
  //   key: "/vipmessaging",
  // },
  {
    label: "Terms and conditions",
    key: "/termsandconditions",
  },
  {
    label: "Premium content",
    key: "/premiumcontent",
  },
];

function Header({header}) {
    const navigate = useNavigate();
  const [current, setCurrent] = useState("mail");
  const [open, setOpen] = useState(false);


  const content = (
    <div>
      <Button className="profile_btn" onClick={() => navigate("/profile")}>Profile</Button>
      <Button className="profile_btn">logout</Button>
    </div>
  );
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };


  const onClick = (e) => {
    console.log("click ", e);
    navigate(e.key)
    setCurrent(e.key);
  };
  return (
    <Row justify="center" className={header ? "navigation1": "navigation"}>
      <Col xs={24} lg={22}>
        <Row>
            <div className="top">
            <HiShoppingCart onClick={() => navigate("/my-cart")}/>
            <HiBell/>
            <BiSolidMessageRounded/>
            <BiSearch/>
            <Button className="mainbtn" onClick={() => navigate("/login")}>Sign In</Button>  
            <Button className="mainbtn" onClick={() => navigate("/signup")}>Sign Up</Button>  
            
            </div>
        </Row>
        <Row style={{ alignItems: "center", padding: "15px 0" }}>
          <Col xs={12} sm={12} lg={2} xl={2}>
            <Image className="logo" preview={false} src={Logo} />
          </Col>
          <Col xs={0} sm={0} md={0} lg={16} xl={17} justify="center">
            <Menu
              className="mainMenu"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
            />
          </Col>
          <Col xs={12} sm={12} lg={6} xl={5} justify={"end"}>

            <Row style={{width:'100%', padding:'0px 50px'}}>
              <Col xs={24} sm={24} md={24} lg={0} style={{textAlign:'right'}}>
              <MenuOutlined style={{fontSize:"25px"}} onClick={()=> showDrawer()}/>
              </Col>
            </Row>

            
            <Row>
              <Col xs={0} sm={0} md={0} lg={24}>
              <Popover content={content}>
              <Button className="profile">
                <Image preview={false} src={ProfileIcon} />
              </Button>
            </Popover> 
            <Link to="/livestreaming"><Button className="mainbtn">Live Stream</Button> </Link> 
              </Col>
            </Row>
            {/* */}

            <Drawer
        placement={"left"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"left"}
      >
       
        <Menu
              className="mainMenu"
              onClick={onClick}
              selectedKeys={[current]}
              mode="vertical"
              items={items}
            />
            <div className="mobileprofile">
            <Popover content={content}>
              <Button className="profile">
                <Image preview={false} src={ProfileIcon} />
              </Button>
            </Popover> 
            <Button className="mainbtn">Live Stream</Button>  
            </div>
             
      </Drawer>
           
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Header;
