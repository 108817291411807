import React, { useState } from "react";
import { Row, Col, Card, Checkbox, Button, Modal, Input, Space, Radio } from "antd";
import Logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import BannerIcons from "../../components/banner-icons";
import swal from "sweetalert";

function DegreeDetails() {
  const navigate = useNavigate();
  const onChangeCheck = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };
  const [bannerCards, setBannerCards] = useState([
    {
      key: 1,
      image: "./images/banner-icon-7.png",
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
  ]);
  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState(1);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [stateChange , setStateChange] = useState(false)
  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const handleSubmit = () => {
    // setIsModalOpen(false);
    setStateChange(true)
    // swal(
    //   "System Message!",
    //   "Your Answers Against The Questions Have Been Submitted!. You'll Be Notified Whether You're Eligible To Purchase This Content Or Not.",
    //   "Okay"
    // );

  };

  return (
    <>
      <div>
        <Row justify="center" className="whitebg">
          <Col xs={22} md={20} xl={18}>
            <Row justify="center">
              <Col xs={22} md={20} xl={14}>
                <Card className="reviewarea text-center">
                  <h3>
                    Welcome to Rituals Masonic1, brother and thank you for
                    taking the Q&A 1* degree Apprentice
                  </h3>
                  <p>Please enter your PCS and click on membership status:</p>

                  <BannerIcons
                    bannerCards={bannerCards}
                    bannerIconClass="bannercards3"
                  />

                  <p>
                    Please click on, 
                    <span className="goldencolor">#1ARMNP245</span> for
                    pre-qualification or exit
                  </p>
                  <p className="text-left">
                    Brother,
                    <br />
                    Please point out in the right, the symbols in which you have
                    taken the oath upon received this rank and how did you trip
                    if any! Then, how many? Responses are numerically mark, and
                    the proper responses will be added automatically and the
                    right result, will qualify you. If you fail, you will be
                    granted a second and last chance to be qualified.
                  </p>

                  <Checkbox.Group
                    style={{
                      width: "100%",
                      textAlign: "left",
                    }}
                    onChange={onChangeCheck}
                  >
                    <Row>
                      <Col span={6}>
                        <Checkbox value="Stone">Stone</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="Malott">Malott</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="At the lodge">At the lodge</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="Bible">Bible</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="Ruler">Ruler</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="Compass">Compass</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="Autel">Autel</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="Square">Square</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="West to East">West to East</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="Five trips">Five trips</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="East to West">East to West</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="Two trips">Two trips</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="West to North">West to North</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="Three Trips">Three Trips</Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>

                  <Button className="mainbtn margin-y-30" onClick={showModal2}>
                    Submit Now!
                  </Button>

                  <Button className="mainbtn margin-y-30 margin-x-30" onClick={showModal}>
                    
                    inner page
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        {!stateChange ? (
          <Row justify={"center"}>
            <Col xs={22} md={22}>
              <h4>Your response was correct!</h4>
            </Col>

            <Col xs={22} md={22}>
              <div className="modalinner">
                <p>Please Enter Your PCS</p>
              </div>
              <div>
                <Space.Compact
                  style={{
                    width: "100%",
                    marginBottom: "25px",
                  }}
                >
                  <Input defaultValue="Your PCS Here" />
                  <Button type="primary" className="mainbtn">
                    Submit
                  </Button>
                </Space.Compact>
              </div>
            </Col>

            <Col xs={22} md={22}>
              <div className="modalinner">
                <p>
                  Menu Option Will Be Shown Below: Please Click On, #1ARMNP
                  -Option
                </p>
              </div>
            </Col>

            <Col xs={22} md={22} style={{ textAlign: "center" }}>
              <div className="modalinner">
                <Button
                  className="mainbtn padding-x-50"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row justify={"center"}>
            <Col xs={22} md={22}>
              <h4>RITUALS MASONIC1</h4>
            </Col>

            <Col xs={22} md={22}>
              <div className="modalinner">
                <p>Welcome To #1ARMNP245-Option Menu, You Are Now Qualified.</p>
              </div>
              <div>
              <Radio.Group onChange={onChangeRadio} value={value}>
              <Space direction="vertical">
        <Radio value={1}>Please Click On, <span className="goldencolor">1ARMP</span> For Purchase, This Action Should Send
This Ritual To Cart</Radio>
        <Radio value={2}>Please Click On, <span className="goldencolor">1ARM-MTRO</span> For Menu Topic To Read Only</Radio>
        
      </Space>
              </Radio.Group>
              </div>
            </Col>

            

            <Col xs={22} md={22} style={{ textAlign: "center" }}>
              <div className="modalinner">
                <Button onClick={() => navigate("/library-content-first-rank-topic")}
                  className="mainbtn margin-y-40 padding-x-50"
                > 
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Modal>

      <Modal
        open={isModalOpen2}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Row justify={"center"}>
          <Col xs={22} md={22}>
            <h4>Your answer is incorrect!</h4>
          </Col>

          <Col xs={22} md={22}>
            <div className="modalinner text-center">
              <p>You Have A Second And Last Chance, Do Want To Continue ?</p>
            </div>
          </Col>

          <Col xs={22} md={22} style={{ textAlign: "center" }}>
            <div className="modalinner">
              <Button
                className="mainbtn padding-x-40"
                onClick={() => navigate("/librarycontent")}
              >
                Yes
              </Button>
              <Button
                className="mainbtn padding-x-50 margin-x-10"
                onClick={handleCancel2}
              >
                Later
              </Button>
            </div>
          </Col>
          <Col xs={22} md={22}>
            <div className="modalinner text-center">
              <p>
                Upon Failing To Access This Ritual, We You Suggest That You Join
                A Respectable District Near You For Affiliation.
              </p>
              <p>
                Sincerely,
                <br />
                Rituals Masonic1
              </p>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default DegreeDetails;
