import React, { useState } from "react";
import { Row, Col, List, Typography, Button } from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
function Packages({fullWidth=false, title= true}) {
  const data = [
    {
      key: "1",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "2",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "3",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "4",
      icon: false,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "5",
      icon: false,
      title: "Racing car sprays burning fuel into crowd.",
    },
  ];
  const data2 = [
    {
      key: "1",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "2",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "3",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "4",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "5",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
  ];
  const [bannerCards, setBannerCards] = useState([
    {
      key: 1,
      image: "./images/banner-icon-7.png",
      title: "Religious Articles",
    },
    {
      key: 2,
      image: "./images/banner-icon-4.png",
      title: "Library Access",
    },
    {
      key: 3,
      image: "./images/banner-icon-8.png",
      title: "Masonic Articles",
    },
    {
      key: 4,
      image: "./images/banner-icon-6.png",
      title: "Advertise Business",
    },
  ]);

  return (
    <>
      <Row justify="center" className="whitebg">
       {title && <Col xs={22} md={20} xl={18} >
          <Row justify="center">
            <Col xs={22} md={20} xl={14}>
              <h2>
                <Link to={"/login"}>
                  <AiOutlineArrowLeft />
                </Link>
                Subscription Plans
              </h2>
              <p>Premium Membership</p>
            </Col>
          </Row>
        </Col>}
        <Col xs={24}>
          <Row justify="center">
            <Col xs={22}>
              <Row justify={"center"} gutter={40}>
                <Col xs={22} md={9} lg={fullWidth ? 10 :7} >
                  <div className="pricebox">
                    <div className="silverbox">
                      <List
                        header={
                          <div>
                            <h4>Silver Plan</h4>
                          </div>
                        }
                        dataSource={data}
                        renderItem={(item) => (
                          <List.Item>
                            <Typography.Text>
                              {item.icon ? (
                                <FaCheck
                                  size={18}
                                  style={{ color: "#59E500" }}
                                />
                              ) : (
                                <ImCross
                                  size={18}
                                  style={{ color: "#FF1D1D" }}
                                />
                              )}
                            </Typography.Text> 
                            {item.title}
                          </List.Item>
                        )}
                      />
                      <div className="ribbon ribbon-top-left">
                        <span>$3.5 PAID MONTHLY</span>
                      </div>
                      <Link to={"/carddetails"}><Button>Select Plan</Button></Link>
                    </div>
                  </div>
                </Col>

                <Col xs={22} md={9} lg={fullWidth ? 10 :7}>
                  <div className="pricebox">
                    <div className="silverbox blackbox">
                      <List
                        header={
                          <div>
                            <h4>Premium Plan</h4>
                          </div>
                        }
                        dataSource={data2}
                        renderItem={(item) => (
                          <List.Item>
                            <Typography.Text>
                              {item.icon ? (
                                <FaCheck
                                  size={18}
                                  style={{ color: "#59E500" }}
                                />
                              ) : (
                                <ImCross
                                  size={18}
                                  style={{ color: "#FF1D1D" }}
                                />
                              )}
                            </Typography.Text> 
                            {item.title}
                          </List.Item>
                        )}
                      />
                      <div className="ribbon ribbon-top-left">
                        <span>$36 PAID Annually</span>
                      </div>
                      <Link to={"/carddetails"}><Button>Select Plan</Button></Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Packages;
