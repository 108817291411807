import React, {useState} from "react";
import { Col, Row, Image, Card,  Button, Modal, Radio} from "antd";
import { useNavigate, Link } from "react-router-dom";

function BannerIcons({bannerCards, bannerIconClass}) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Row justify="center">
        <Col xs={22} md={20} xl={20}>
          <Row gutter={20}  justify="center">
            {bannerCards.length > 0 &&
              bannerCards.map((item, index) => {
                return( <Col xs={24} sm={12} md={bannerCards.length == 1 ? 16 : 8} lg={bannerCards.length == 1 ? 16 : 8} xl={bannerCards.length==6 ? 4:bannerCards.length == 1 ? 12 : 6} key={index}>
                    {item.title === "Library Access"?<Card onClick={showModal}  className={bannerIconClass}
                    >
                      <Image preview={false} src={item.image} />
                      <p>{item.title}</p>
                    </Card>:
                    <Link to={item?.link}><Card className={bannerIconClass}
                    >
                      <Image preview={false} src={item.image} />
                      <p>{item.title}</p>
                      {item.des && <p>{item?.des}</p>}
                    </Card></Link>}
                  </Col>);
               
              })}
          </Row>
        </Col>
      </Row>

      <Modal  open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
      <Row justify={"center"}>
          <Col xs={22} md={22}>
            <h4>Congrats!</h4>
          </Col>

          <Col xs={22} md={22}>
            <div className="modalinner">
              <p>
                You Are Now Eligible To Purchase The Book. Please Choose Book
                Type Below:
              </p>
              <Radio.Group >
                <Radio value={1}>I'm good with E-book</Radio>
                <Radio value={2}>I want a hardcopy</Radio>
              </Radio.Group>
            </div>
          </Col>

          <Col xs={22} md={22} style={{ textAlign: "center" }}>
            <div className="modalinner">
              <Button className="mainbtn padding-x-40" onClick={() => navigate("/librarycontent")}>Checkout</Button>
              <Button className="mainbtn padding-x-50 margin-x-10"  onClick={handleCancel}>
                Later
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default BannerIcons;
