import React from 'react'
import { Row, Col, Image, Avatar, Input,  Button } from 'antd'
import { AiOutlineLike} from 'react-icons/ai';
import { FaRegCommentDots} from 'react-icons/fa';
import { ImShare2} from 'react-icons/im';

function Post() {
  return (
    <div>
        
            
            <Row justify="center" gutter={30} className='info-area padding-y-40 greybg margin-y-40 border-radius-15'>
            <Col  xs={23} md={22} xl={22}>
            <Row gutter={20} className='margin-y-15'>
                        <Col xs={5} md={4} xl={2}>
                        <Avatar size={48} icon={<Image preview={false} src="./images/service1.png" />} />
                        </Col>
                        <Col  xs={19} md={20} xl={22}>
                            <h5 className='margin-0'>ADMINRM</h5>
                            <p>Posted at 2h ago </p>
                        </Col>
                    </Row>
            </Col>
            <Col xs={23} md={22} xl={22}>
                <p>Ulus Mus. Nam Fermentum, Nulla Luctus Pharetra Vulputate, Felis Tellus Mollis Orci, </p>

                <div className='postimage'>
                    <Image preview={false} src="./images/post-image.png" width={"100%"} />
                    <Row justify={'center'}>
                        <Col xs={23}>
                        <Row justify={'space-around'}>
                        <Col xs={12} md={12} xl={12}  style={{ justifyContent: 'start', display:'flex'}}>
                            <span className='gradient'>
                            <AiOutlineLike fontSize={24}/>
                            <span>36 Likes</span>
                            </span>
                        </Col>
                        <Col xs={12} md={12} xl={12} style={{ justifyContent: 'end', display:'flex'}}>
                        <span className='gradient'>
                            <FaRegCommentDots  fontSize={24}/>
                            <span>256 Comments</span>
                            </span>
                        </Col>
                        </Row>
                        </Col>
                        
                    </Row>
                    <hr className='margin-y-40'/>
                </div>

                <Row className='sharingarea'>
                    <Col xs={8} md={6} lg={3} xl={3} >
                    <AiOutlineLike fontSize={20}/>
                            <span>36 Likes</span>
                    </Col>
                    <Col xs={10} md={6} lg={5} xl={4}>
                    <FaRegCommentDots  fontSize={20}/>
                            <span>256 Comments</span>
                    </Col>
                    <Col xs={6} md={6} lg={3} xl={3}>
                    <ImShare2 fontSize={20}/>
                            <span>Share</span>
                    </Col>
                </Row>

                <Row className='margin-y-30' gutter={20}>
                    <Col xs={5} md={4} lg={2} xl={1}> <Avatar size={32} icon={<Image preview={false} src="./images/service1.png" />} /></Col>
                    <Col xs={19} md={20} lg={22} xl={23}>
                    <p>Ulus Mus. Nam Fermentum, Nulla Luctus Pharetra Vulputate, Felis Tellus Mollis Orci, </p>
                    
                    </Col>
                </Row>

                <Row className='margin-y-30' gutter={20}>
                    <Col xs={5} md={4} lg={2} xl={1}> <Avatar size={32} icon={<Image preview={false} src="./images/service1.png" />} /></Col>
                    <Col xs={19} md={20} lg={22} xl={23}>
                    <Input placeholder="Abc" className='border-radius-15' />
                    
                    </Col>
                </Row>
                <Row justify={"end"}>
                    <Col>
                    <Button className="mainbtn padding-x-40">Post</Button> 
                    </Col>
                </Row>
            </Col>
            </Row>
            
       

        

      </div>
  )
}

export default Post