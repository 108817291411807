import { Layout,Row,Col, Card, List } from 'antd'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { FaUserCircle  } from "react-icons/fa"
import { BsCollectionPlay,BsBellFill  } from "react-icons/bs"
import { MdBorderStyle  } from "react-icons/md"
import { LiaLanguageSolid  } from "react-icons/lia"

function Profile() {
    const navigate = useNavigate();
  return (
    <>
    <Layout style={{minHeight:"100vh"}} className='whitebg'>
        <Row justify={"center"}>
            <Col xs={22} md={22} >
                    <Row justify={"center"} gutter={40}>
                    <Col xs={22} md={6} className='sidebar' >
                        <h4>Hello, Jana James</h4>
                        <Card>
                        <List
                            itemLayout="horizontal"                           
                        >
                              <List.Item onClick={() => navigate("/profile")}>
                                <List.Item.Meta
                                 title={<><FaUserCircle/> &emsp; {"My Profile"}</>}
                               
                                />
                            </List.Item>
                            <List.Item onClick={() => navigate("manage-subscription")}>
                                <List.Item.Meta
                                title={<><BsCollectionPlay/> &emsp; {"Manage Subscriptions"}</>}
                               
                                />
                            </List.Item>
                            <List.Item onClick={() => navigate("order-history")}>
                                <List.Item.Meta
                               title={<><MdBorderStyle/> &emsp; {"Order & History"}</>}
                               
                                />
                            </List.Item>
                            <List.Item onClick={() => navigate("")}>
                                <List.Item.Meta
                                title={<><BsBellFill/> &emsp; {"Push Notifications"}</>}
                               
                                />
                            </List.Item>
                            <List.Item onClick={() => navigate("")}>
                                <List.Item.Meta
                                title={<><LiaLanguageSolid/> &emsp; {"Language"}</>}
                               
                                />
                            </List.Item>
                        </List>
                        </Card>
                    </Col>
                    <Col xs={22} md={18} >
                        <Outlet />
                    </Col>
                   </Row>
            </Col>
        </Row>
    </Layout>
     
    </>
  
  )
}

export default Profile