import React from "react";
import { Row, Col } from "antd";
import { Player } from "video-react";

function LiveStreaming() {
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <Row justify="center">
            <Col xs={22} md={20} xl={14}>
              <h2>Live Meeting</h2>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua
              </p>
            </Col>
          </Row>
          <Row justify="center" gutter={50} className="padding-y-40 tnc">
            <Col xs={22} md={22} lg={22}>
              <Player
                playsInline
                poster="./images/video-poster.png"
                src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                className="home-video-box"
                controls={false}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default LiveStreaming;
