
import React, {useState} from 'react'
import { Row, Col, Image, Avatar } from 'antd'


function OnlineServices() {
    const [onlineService, setOnlineServices] = useState([
        {
          key: 1,
          image: "./images/service1.png",
          title: "Dummy Online Services",
          avatar:"./images/service1.png",
          avatarName:"ADMINRM",
          avatarTimeDate:"02 days ago   I  08:00 AM",
          text:"Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
          bottomTitle:"Contact Details",
          contactInfo:"+012 345 6789",

        },
        {
          key: 2,
          image: "./images/service1.png",
          title: "Dummy Online Services",
          avatar:"./images/service1.png",
          avatarName:"ADMINRM",
          avatarTimeDate:"02 days ago   I  08:00 AM",
          text:"Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
          bottomTitle:"Contact Details",
          contactInfo:"+012 345 6789",
        },
        {
          key: 3,
          image: "./images/service1.png",
          title: "Dummy Online Services",
          avatar:"./images/service1.png",
          avatarName:"ADMINRM",
          avatarTimeDate:"02 days ago   I  08:00 AM",
          text:"Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
          bottomTitle:"Contact Details",
          contactInfo:"+012 345 6789",
        },
        {
          key: 4,
          image: "./images/service1.png",
          title: "Dummy Online Services",
          avatar:"./images/service1.png",
          avatarName:"ADMINRM",
          avatarTimeDate:"02 days ago   I  08:00 AM",
          text:"Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
          bottomTitle:"Contact Details",
          contactInfo:"+012 345 6789",
        }
      ]);
  return (
    <>
      <div>
        <Row justify="center"  className='whitebg'>
        <Col  xs={22} md={20} xl={18}>
            <Row justify="center"> 
            <Col  xs={22} md={20} xl={14}>
                <h2>Online Services</h2>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua</p>
            </Col>
            </Row>
            {onlineService.length > 0 &&
              onlineService.map((item, index) => {
                return( <Row justify="center" gutter={30} className='info-area padding-y-40 greybg margin-y-40 border-radius-15' key={index}>
                <Col xs={22} md={22} lg={11} >
                <Image preview={false} src={item.image} />
                    
                    
                    </Col>
                <Col xs={22} md={22} lg={11}>
                    <h3>{item.title}</h3>
                    <Row gutter={20} className='margin-y-15'>
                        <Col>
                        <Avatar size={48} icon={<Image preview={false} src={item.avatar} />} />
                        </Col>
                        <Col>
                            <h5 className='margin-0'>{item.avatarName}</h5>
                            <p>{item.avatarTimeDate}</p>
                        </Col>
                    </Row>
                    <p>{item.text}</p>
                    <h5>{item.bottomTitle}</h5>
                    <p>{item.contactInfo}</p>
                </Col>
            </Row>);
               
              })}
            
        </Col>
        </Row>

        

      </div>
    </>
  )
}

export default OnlineServices