import React from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Checkbox,
  Card,
  Table,
  Image,
  Divider,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { FiMail, FiLock } from "react-icons/fi";
import swal from "sweetalert";

// import router from "next/router";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    return;
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
    };
    Post(AUTH.signin, data)
      .then((response) => {
        console.log(response, "#####");
        setLoading(false);
        if (response?.data?.status) {
          navigate("/dashboard", { replace: true });

          dispatch(
            addUser({
              user: response.data.data.user,
              token: response.data.data.token,
            })
          );
          swal("Success", response.data.message, "success");
        } else {
          console.log("response", response);
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout className="banner">
      <Row className="flex" style={{ minHeight: "100vh" }}>
        <Col xs={24} md={8}>
          <Card>
            <div className="authFormBox">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                  

                  <Typography.Title
                    className="fontFamily1"
                    style={{ fontSize: "30px", color: "black", textTransform:"uppercase"  }}
                  >
                    Log Into you account
                    <p style={{ fontSize: "12px", color: "black" }}>
                      Enter Your Credentials To Log In To The Platform
                    </p>
                  </Typography.Title>
                  <br />
                  <Form
                    layout="vertical"
                    name="basic"
                    className="loginForm"
                    labelCol={{
                      span: 0,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email Address"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Please input valid email!",
                          // warningOnly: true,
                        },
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Email Address"
                        className="AuthFormInput"
                        
                      />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        
                        placeholder="Enter Password"
                        className="AuthFormInput"
                      />
                    </Form.Item>
                    <Row>
                      <Col xs={24} md={12}>
                        <Form.Item
                          name="remember"
                          valuePropName="checked"
                          style={{
                            marginBottom: 0,
                            color: "black",
                            textAlign: "left",
                          }}
                        >
                          <Checkbox
                            style={{ marginBottom: 0, color: "#AE802C" }}
                          >
                             
                            <p
                              className="fontFamily1"
                              style={{
                                margin: 0,
                                color: "#AE802C",
                                fontSize: "12px",
                              }}
                            >
                              Remember Me
                            </p>
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Button
                          type="link"
                          style={{
                            float: "right",
                            fontWeight: "bold",
                            textDecoration: "underline",
                            fontSize: "12px",
                          }}
                          onClick={() => navigate("/forgetpassword")}
                        >
                          <p
                            className="fontFamily1"
                            style={{
                              margin: 0,
                              color: "black",
                              fontSize: "12px",
                            }}
                          >
                            Forgot Password?
                          </p>
                        </Button>
                      </Col>
                    </Row>
                    <br />

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="loginButton mainbtn"
                      >
                        {loading ? "Loading..." : "Login"}
                      </Button>
                    </Form.Item>
                  </Form>

                  <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "14px",
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 30,
                    }}
                  >
                    <>
                      Don't have an account? 
                      <span
                        onClick={() => navigate("/signup")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          textDecoration: "underline",
                          color: "#AE802C",
                        }}
                      >
                        Sign up
                      </span>
                    </>
                  </Typography.Text>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default Login;
