import React, { useState } from "react";
import { Row, Col, Image, Avatar, Button } from "antd";
import { useNavigate} from "react-router-dom";
import ImageGrid from "../../components/imagegrid";
import {AiOutlineArrowLeft} from 'react-icons/ai';

function AdvertiseBusiness() {
  const navigate = useNavigate();
  const images = [
    "./images/service1.png",
    "./images/upcoming4.png",
    "./images/upcoming3.png",
  ];
  const [onlineService, setOnlineServices] = useState([
    {
      key: 1,
      title: "Dummy Online Services",
      avatar: "./images/service1.png",
      avatarName: "ADMINRM",
      avatarTimeDate: "02 days ago   I  08:00 AM",
      text: "Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      bottomTitle: "Contact Details",
      contactInfo: "+012 345 6789",
    },
    {
      key: 2,
      title: "Dummy Online Services",
      avatar: "./images/service1.png",
      avatarName: "ADMINRM",
      avatarTimeDate: "02 days ago   I  08:00 AM",
      text: "Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      bottomTitle: "Contact Details",
      contactInfo: "+012 345 6789",
    },
    {
      key: 3,
      title: "Dummy Online Services",
      avatar: "./images/service1.png",
      avatarName: "ADMINRM",
      avatarTimeDate: "02 days ago   I  08:00 AM",
      text: "Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      bottomTitle: "Contact Details",
      contactInfo: "+012 345 6789",
    },
  ]);
  return (
    <>
      <div>
        <Row justify="center" className="whitebg">
          <Col xs={22} md={20} xl={18}>
            <Row justify="space-between" align={"middle"}>
              <Col xs={2} md={2} xl={1}><AiOutlineArrowLeft style={{fontSize:"25px"}} /></Col>
              <Col xs={22} md={19} xl={19}>
                <h2>Advertise Business </h2>
                
              </Col>
              <Col xs={22} md={4} xl={3} justify={"end"}><Button className="mainbtn" onClick={() => navigate("/createadd")}>Create Ad</Button>  </Col>
              
            </Row>
            <Row  justify="center">
            <Col xs={22} md={18} xl={16}>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua
                </p>
              </Col>
            </Row>
            {onlineService.length > 0 &&
              onlineService.map((item, index) => {
                return (
                  <Row
                    justify="center"
                    gutter={30}
                    className="info-area padding-y-40 greybg margin-y-40 border-radius-15"
                    key={index}
                  >
                    <Col xs={22} md={22} lg={11}>
                      <ImageGrid smallImages={images} />
                    </Col>
                    <Col xs={22} md={22} lg={11}>
                      <h3>{item.title}</h3>
                      <Row gutter={20} className="margin-y-15">
                        <Col>
                          <Avatar
                            size={48}
                            icon={<Image preview={false} src={item.avatar} />}
                          />
                        </Col>
                        <Col>
                          <h5 className="margin-0">{item.avatarName}</h5>
                          <p>{item.avatarTimeDate}</p>
                        </Col>
                      </Row>
                      <p>{item.text}</p>
                      <h5>{item.bottomTitle}</h5>
                      <p>{item.contactInfo}</p>
                    </Col>
                  </Row>
                );
              })}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AdvertiseBusiness;
